<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>部门：</span>
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span> 单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center">
              <span> 项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请输入或选择项目名称"
                class="ipt_width project_width"
                @change="dataUpdate"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED" label="未审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS" label="已审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT" label="不通过"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            :row-class-name="tableRowClassName"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              width="100"
              prop="createName"
              label="姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable
              prop="deptName"
              label="部门"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="postName" label="岗位"></el-table-column> -->
            <el-table-column
              align="center"
              prop="projectName"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="项目名称"
            ></el-table-column>
            <el-table-column align="center" width="110" sortable prop="sumCost" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              v-if="parameter.approvalType == '2'"
              width="150"
              prop="status"
              label="报销进度"
            >
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.status) }}
              </template>
            </el-table-column> -->
            <el-table-column align="center" width="110" sortable prop="taskDate" label="申请时间">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(expenseModeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>

            <!-- <el-table-column align="center" prop="sumCost" label="当月报销总费用"></el-table-column> -->

            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Examine_Bgc"
                  @click.stop="audit(scope.row)"
                  v-if="parameter.approvalType == 'UNDETERMINED'"
                  >审核</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="parameter.approvalType != 'UNDETERMINED'"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="项目报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/项目报销流程图.png" alt="项目报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit :isShow.sync="showEditDialog" v-if="showEditDialog" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        userName: null,
        deptId: null,
        expenseNumber: null,
        projectId: null,
        pageNow: 1,
        pageSize: 50,
        total: 0,
        approvalType: 'UNDETERMINED', //1未审核，2已审核，3未通过
      },
      pageSize: 0,
      // dictData: {
      //   status: {},
      // },
      form: {},
      dg_form: {},
      tableData: [],
      loading: false,
      editDialogLoading: false,
      showEditDialog: false,
      projectList: [], // 项目数据
      deptList: [], // 部门 列表
      drawer: false,
      createdDate: [],
      expenseModeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
    }
  },
  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.getProjectList() /** 加载 "项目" 数据 **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    // this.getStatus(); /** 加载 "报销状态" 列表数据 **/

    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
      this.expenseModeList = res.data
    })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  provide() {
    return {
      re: this.re,
    }
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    /** 加载 "项目" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data ? res.data : []
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    getData() {
      this.loading = true
      // this.parameter.approvalType = 1
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }

      this.$api.projectCost
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    audit(row) {
      this.form = row
      this.showEditDialog = true
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.showStatus == 1) {
        return 'warning-row'
      }
      return ''
    },
    re() {
      this.form = {}
      this.getData()
      this.showEditDialog = false
    },
    detail(row) {
      this.form = {
        list_s: 2,
        ...row,
      }
      this.showEditDialog = true
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.expenseNumber = null
      this.parameter.userName = null
      this.parameter.projectId = null
      this.parameter.deptId = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载  数据 **/
    },
    dataUpdate() {
      this.$forceUpdate()
    },
    /**  商务报销限额字典 **/
    // getStatus() {
    //   this.$api.dict.listSysDictData("PROJECT_COST_STATUS", true).then((res) => {
    //     this.dictData.status = res.data;
    //   });
    // },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: calc(100vh - 347px);
    }
  }
}

.dg_title {
  padding-bottom: 10px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
/deep/ .el-table .warning-row {
  background: oldlace;
}
.waring_block {
  width: 16px;
  height: 16px;
  background: oldlace;
  display: inline-block;
  border: 1px solid #f9f9f9;
  vertical-align: text-bottom;
}
</style>
